import React, { useState } from 'react'
import member1 from "./../../assets/aboutus/team/member1.jpg"
import member2 from "./../../assets/aboutus/team/member2.jpg"
import member3 from "./../../assets/aboutus/team/member3.jpg"
import member4 from "./../../assets/aboutus/team/member4.jpg"
import member5 from "./../../assets/aboutus/team/member5.jpeg"
import member6 from "./../../assets/aboutus/team/member6.jpeg"

import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { FADE_DOWN_ANIMATION_VARIANTS } from "../../lib/framer";
import TeamInfo from '../../Modals/TeamInfo'

const Teams = () => {
    const [isOpen, setIsOpen] = useState(false)
    const [info, setInfo] = useState([{
        id: 1,
        designation: "Co-founder, Digifolio",
        name: "Azat Shahanov",
        description: `Azat Shahanov is one of the visionary co-founders behind Digifolio, a dynamic tech company at the forefront of digital innovation.
        With a rich background in IT, business development, and leadership, Azat brings a wealth of experience to the table.
    
        As a co-founder, Azat played a pivotal role in shaping Digifolio's vision and direction from its inception.
        His strategic insights, coupled with a keen understanding of market trends and emerging technologies, have been instrumental in driving Digifolio's growth and success.
    
        Azat's passion for innovation and commitment to excellence are evident in every aspect of Digifolio's operations.
        Whether it's devising groundbreaking solutions, forging strategic partnerships, or fostering a culture of creativity and collaboration,
        Azat's influence permeates throughout the organization.
    
        Under his leadership, Digifolio continues to push the boundaries of what's possible in the digital realm,
        delivering cutting-edge products and services that empower businesses and individuals alike to thrive in the digital age.
        As a co-founder, Azat remains dedicated to realizing Digifolio's mission of driving positive change through technology,
        ensuring a brighter and more connected future for all.`
    },
    {
        id: 2,
        name: "Luis García",
        designation: "Brand Ambassador",
        description: `Luis García, renowned for his achievements on the football pitch, now serves as the Brand Ambassador for Digifolio. 
        With a career marked by determination, skill, and a winning mindset, García embodies the values of excellence and innovation that Digifolio strives to uphold.
        
        As Brand Ambassador, García leverages his global influence and credibility to promote 
        Digifolio's mission of technological advancement and digital innovation. 
        His endorsement signifies a partnership built on shared values of integrity, performance, and a relentless pursuit of success.
        García's reputation as a leader both on and off the field aligns perfectly with Digifolio's vision to lead the way in the digital landscape. 
        His involvement enhances Digifolio's brand visibility and credibilit.`
    },
    {
        id: 3,
        name: "Marc Domenech",
        designation: "Tech Advisor",
        description: `Bringing nearly two decades of experience from his time at NVIDIA in the META region, 
        Marc Domenech now joins Digifolio as a tech Advisor. In this role, he will spearhead business
         engagement strategies,drive growth, and expand the scope of Digifolio's initiatives. 
        Marc is set to uncover pivotal strategic opportunities that will propel Digifolio to new heights,
        particularly in its AI-focused projects.`
    },
    {
        id: 4,
        name: "Himanshu Gulati",
        designation: "Legal Advisor",
        description: `Himanshu Gulati is a Norwegian politician representing the Progress Party. Gulati was the chairperson of the Progress Party's Youth from 2012 to 2014. He served as State Secretary in the Ministry of Justice and Public Security from 2013 to 2014, and at the Office of the Prime Minister from 2014 until 2017.`
    },
    {
        id: 5,
        name: "Muhammad Taufique",
        designation: "Development Advisor",
        description: `Taufique is a Co-Founder @ Blockliz, a cutting-edge technology company that is revolutionizing the way we access the internet. With his strong background in blockchain development and consulting, he has launched over 100 + successful projects for international clients and currently runs Blockliz, a blockchain development firm in Dubai.`
    },
    {
        id: 6,
        name: "Hadir Gaber",
        designation: "Marketing Advisory",
        description: `Over 4 years of experience in blockchain and cryptocurrency as a trader and instructor. She has served as a KOL and brand ambassador for prominent crypto projects. She supports startups with connections, marketing, and branding strategies.`
    }
    ]);
    const [index, setIndex] = useState('')

    const { ref, inView } = useInView({
        triggerOnce: false,
    });

    const readMore = (index) => {
        setIndex(index)
        setIsOpen(!isOpen);
    }

    return (
        <div class="flex items-center justify-center  py-24">
            <motion.div
                ref={ref}
                initial="hidden"
                animate={inView ? "show" : "hidden"}
                viewport={{ once: false }}
                variants={{
                    hidden: {},
                    show: {
                        transition: {
                            staggerChildren: 0.5,
                        },
                    },
                }}
                class="flex flex-col">
                {/* <!-- Notes --> */}


                <div class="flex flex-col mt-8">
                    {/* <!-- Meet the Team --> */}
                    <div class="container max-w-7xl px-4">
                        {/* <!-- Section Header --> */}
                        <div class="flex flex-wrap justify-center text-center mb-16">
                            <div class="w-full lg:w-6/12 px-4">
                                {/* <!-- Header --> */}
                                <motion.h1
                                    variants={FADE_DOWN_ANIMATION_VARIANTS}
                                    class="text2 text-4xl font-bold mb-8">
                                    Meet the Team
                                </motion.h1>

                                {/* <!-- Description --> */}
                                <motion.p
                                    variants={FADE_DOWN_ANIMATION_VARIANTS}
                                    class="text2 text-lg font-light">
                                    With over 100 years of combined experience, we've got a well-seasoned team at the Digifolio.
                                </motion.p>
                            </div>
                        </div>
                        {/* 
                <!-- Team Members --> */}
                        <div class="flex flex-wrap">
                            {/* <!-- Member #1 --> */}
                            <motion.div
                                variants={FADE_DOWN_ANIMATION_VARIANTS}
                                class="w-full md:w-6/12 lg:w-3/12 mb-6 px-6 sm:px-6 lg:px-4">
                                <div class="flex flex-col">
                                    {/* <!-- Avatar --> */}
                                    <div class="mx-auto">
                                        <img class="rounded-2xl h-72 w-60 drop-shadow-md hover:drop-shadow-xl transition-all duration-200 delay-100"
                                            src={member1} />
                                    </div>

                                    {/* <!-- Details --> */}
                                    <div class="text-center mt-6">
                                        {/* <!-- Name --> */}
                                        <h1 class=" text-xl font-bold mb-1 ">
                                            Azat Shahanov

                                        </h1>

                                        {/* <!-- Title --> */}
                                        <div class=" font-light mb-2 justify-center flex  gap-2 w-full">
                                            Co-founder, Digifolio <a href='https://www.linkedin.com/in/azat-shahanov/' target='_blank'>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 21a9.004 9.004 0 0 0 8.716-6.747M12 21a9.004 9.004 0 0 1-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 0 1 7.843 4.582M12 3a8.997 8.997 0 0 0-7.843 4.582m15.686 0A11.953 11.953 0 0 1 12 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0 1 21 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0 1 12 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 0 1 3 12c0-1.605.42-3.113 1.157-4.418" />
                                            </svg>
                                            </a>
                                        </div>
                                        <div class=" font-light mb-2 text-start">
                                            Azat Shahanov is one of the visionary co-founders behind Digifolio, a dynamic tech company at the forefront of digital innovation.
                                            With a rich  <span onClick={(() => { readMore(0) })} className='cursor-pointer text-brand font-medium'> read more ...</span>
                                        </div>


                                        {/* <!-- Social Icons --> */}
                                        <div class="flex items-center justify-center opacity-50 hover:opacity-100
                                transition-opacity duration-300">
                                            {/* <!-- Linkedin --> */}
                                            <a href="#" class="flex rounded-full hover:bg-indigo-50 h-10 w-10">
                                                <i class="mdi mdi-linkedin text-indigo-500 mx-auto mt-2"></i>
                                            </a>

                                            {/* <!-- Twitter --> */}
                                            <a href="#" class="flex rounded-full hover:bg-blue-50 h-10 w-10">
                                                <i class="mdi mdi-twitter text-blue-300 mx-auto mt-2"></i>
                                            </a>

                                            {/* <!-- Instagram --> */}
                                            <a href="#" class="flex rounded-full hover:bg-orange-50 h-10 w-10">
                                                <i class="mdi mdi-instagram text-orange-400 mx-auto mt-2"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </motion.div>

                            {/* <!-- Member #2 --> */}
                            <motion.div
                                variants={FADE_DOWN_ANIMATION_VARIANTS}
                                class="w-full md:w-6/12 lg:w-3/12 mb-6 px-6 sm:px-6 lg:px-4">
                                <div class="flex flex-col">
                                    {/* <!-- Avatar --> */}
                                    <a href="#" class="mx-auto">
                                        <img class="rounded-2xl w-60 h-72 drop-shadow-md hover:drop-shadow-xl transition-all duration-200 delay-100"
                                            src={member2} />
                                    </a>

                                    {/* <!-- Details --> */}
                                    <div class="text-center mt-6">
                                        {/* <!-- Name --> */}
                                        <h1 class=" text-xl font-bold mb-1">
                                            Luis García
                                        </h1>

                                        {/* <!-- Title --> */}
                                        
                                        <div class=" font-light mb-2 justify-center flex  gap-2 w-full">
                                        Brand Ambassador <a href='https://en.wikipedia.org/wiki/Luis_Garc%C3%ADa_(footballer,_born_1978)' target='_blank'>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 21a9.004 9.004 0 0 0 8.716-6.747M12 21a9.004 9.004 0 0 1-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 0 1 7.843 4.582M12 3a8.997 8.997 0 0 0-7.843 4.582m15.686 0A11.953 11.953 0 0 1 12 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0 1 21 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0 1 12 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 0 1 3 12c0-1.605.42-3.113 1.157-4.418" />
                                            </svg>
                                            </a>
                                        </div>
                                        <div class=" font-light mb-2 text-start">
                                            Luis García, renowned for his achievements on the football pitch, now serves as the Brand Ambassador for Digifolio.
                                            With a career <span onClick={(() => { readMore(1) })} className='cursor-pointer text-brand font-medium'> read more ...</span>
                                        </div>

                                        {/* <!-- Social Icons --> */}
                                        <div class="flex items-center justify-center opacity-50 hover:opacity-100
                                transition-opacity duration-300">
                                            {/* <!-- Linkedin --> */}
                                            <a href="#" class="flex rounded-full hover:bg-indigo-50 h-10 w-10">
                                                <i class="mdi mdi-linkedin text-indigo-700 mx-auto mt-2"></i>
                                            </a>

                                            {/* <!-- Twitter --> */}
                                            <a href="#" class="flex rounded-full hover:bg-blue-50 h-10 w-10">
                                                <i class="mdi mdi-twitter text-blue-400 mx-auto mt-2"></i>
                                            </a>

                                            {/* <!-- Instagram --> */}
                                            <a href="#" class="flex rounded-full hover:bg-orange-50 h-10 w-10">
                                                <i class="mdi mdi-instagram text-orange-400 mx-auto mt-2"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </motion.div>

                            {/* <!-- Member #3 --> */}
                            <motion.div variants={FADE_DOWN_ANIMATION_VARIANTS} class="w-full md:w-6/12 lg:w-3/12 mb-6 px-6 sm:px-6 lg:px-4">
                                <div class="flex flex-col">
                                    {/* <!-- Avatar --> */}
                                    <a href="#" class="mx-auto">
                                        <img class="rounded-2xl w-60 h-72 drop-shadow-md hover:drop-shadow-xl transition-all duration-200 delay-100"
                                            src={member3} />
                                    </a>

                                    {/* <!-- Details --> */}
                                    <div class="text-center mt-6">
                                        {/* <!-- Name --> */}
                                        <h1 class="text-xl font-bold mb-1">
                                            Marc Domenech
                                        </h1>

                                        {/* <!-- Title --> */}
                                        <div class=" font-light mb-2 justify-center flex  gap-2 w-full">
                                        Tech Advisor <a href='https://www.linkedin.com/in/madomenech/' target='_blank'>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 21a9.004 9.004 0 0 0 8.716-6.747M12 21a9.004 9.004 0 0 1-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 0 1 7.843 4.582M12 3a8.997 8.997 0 0 0-7.843 4.582m15.686 0A11.953 11.953 0 0 1 12 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0 1 21 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0 1 12 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 0 1 3 12c0-1.605.42-3.113 1.157-4.418" />
                                            </svg>
                                            </a>
                                        </div>
                                        <div class=" font-light mb-2 text-start">

                                            Bringing nearly two decades of experience from his time at NVIDIA in the META region,
                                            Marc Domenech now joins Digifolio as a tech Advisor  <span onClick={(() => { readMore(2) })} className='cursor-pointer text-brand font-medium'> read more ...</span>
                                        </div>

                                        {/* <!-- Social Icons --> */}
                                        <div class="flex items-center justify-center opacity-50 hover:opacity-100
                                transition-opacity duration-300">
                                            {/* <!-- Linkedin --> */}
                                            <a href="#" class="flex rounded-full hover:bg-indigo-50 h-10 w-10">
                                                <i class="mdi mdi-linkedin text-indigo-700 mx-auto mt-2"></i>
                                            </a>

                                            {/* <!-- Twitter --> */}
                                            <a href="#" class="flex rounded-full hover:bg-blue-50 h-10 w-10">
                                                <i class="mdi mdi-twitter text-blue-400 mx-auto mt-2"></i>
                                            </a>

                                            {/* <!-- Instagram --> */}
                                            <a href="#" class="flex rounded-full hover:bg-orange-50 h-10 w-10">
                                                <i class="mdi mdi-instagram text-orange-400 mx-auto mt-2"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </motion.div>

                            {/* <!-- Member #4 --> */}
                            <motion.div
                                variants={FADE_DOWN_ANIMATION_VARIANTS}
                                class="w-full md:w-6/12 lg:w-3/12 mb-6 px-6 sm:px-6 lg:px-4">
                                <div class="flex flex-col">
                                    {/* <!-- Avatar --> */}
                                    <a href="#" class="mx-auto">
                                        <img class="rounded-2xl w-60 h-72 drop-shadow-md hover:drop-shadow-xl transition-all duration-200 delay-100"
                                            src={member4} />
                                    </a>

                                    {/* <!-- Details --> */}
                                    <div class="text-center mt-6">
                                        {/* <!-- Name --> */}
                                        <h1 class=" text-xl font-bold mb-1">
                                            Himanshu Gulati
                                        </h1>

                                        {/* <!-- Title --> */}
                                        <div class=" font-light mb-2 justify-center flex  gap-2 w-full">
                                        Legal Advisor <a href='https://en.m.wikipedia.org/wiki/Himanshu_Gulati' target='_blank'>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 21a9.004 9.004 0 0 0 8.716-6.747M12 21a9.004 9.004 0 0 1-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 0 1 7.843 4.582M12 3a8.997 8.997 0 0 0-7.843 4.582m15.686 0A11.953 11.953 0 0 1 12 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0 1 21 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0 1 12 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 0 1 3 12c0-1.605.42-3.113 1.157-4.418" />
                                            </svg>
                                            </a>
                                        </div>
                                        <div class=" font-light mb-2 text-start">
                                            Himanshu Gulati is a Norwegian politician representing the Progress Party. Gulati was the chairperson of the Progress Party's Youth from 2012   <span onClick={(() => { readMore(3) })} className='cursor-pointer text-brand font-medium'> read more ...</span>
                                        </div>

                                        {/* <!-- Social Icons --> */}
                                        <div class="flex items-center justify-center opacity-50 hover:opacity-100
                                transition-opacity duration-300">
                                            {/* <!-- Linkedin --> */}
                                            <a href="#" class="flex rounded-full hover:bg-indigo-50 h-10 w-10">
                                                <i class="mdi mdi-linkedin text-indigo-700 mx-auto mt-2"></i>
                                            </a>

                                            {/* <!-- Twitter --> */}
                                            <a href="#" class="flex rounded-full hover:bg-blue-50 h-10 w-10">
                                                <i class="mdi mdi-twitter text-blue-400 mx-auto mt-2"></i>
                                            </a>

                                            {/* <!-- Instagram --> */}
                                            <a href="#" class="flex rounded-full hover:bg-orange-50 h-10 w-10">
                                                <i class="mdi mdi-instagram text-orange-400 mx-auto mt-2"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </motion.div>
                            {/* <!-- Member #5 --> */}
                            <motion.div
                                variants={FADE_DOWN_ANIMATION_VARIANTS}
                                class="w-full md:w-6/12 lg:w-3/12 mb-6 px-6 sm:px-6 lg:px-4">
                                <div class="flex flex-col">
                                    {/* <!-- Avatar --> */}
                                    <a href="#" class="mx-auto">
                                        <img class="rounded-2xl w-60 h-72 drop-shadow-md hover:drop-shadow-xl transition-all duration-200 delay-100"
                                            src={member5} />
                                    </a>

                                    {/* <!-- Details --> */}
                                    <div class="text-center mt-6">
                                        {/* <!-- Name --> */}
                                        <h1 class=" text-xl font-bold mb-1">
                                            Hadir Gaber
                                        </h1>

                                        {/* <!-- Title --> */}
                                        <div class=" font-light mb-2 justify-center flex  gap-2 w-full">
                                        Marketing Advisory <a href='https://www.instagram.com/hashdeer1/' target='_blank'>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 21a9.004 9.004 0 0 0 8.716-6.747M12 21a9.004 9.004 0 0 1-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 0 1 7.843 4.582M12 3a8.997 8.997 0 0 0-7.843 4.582m15.686 0A11.953 11.953 0 0 1 12 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0 1 21 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0 1 12 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 0 1 3 12c0-1.605.42-3.113 1.157-4.418" />
                                            </svg>
                                            </a>
                                        </div>
                                        <div class=" font-light mb-2 text-start">
                                            Over 4 years of experience in blockchain and cryptocurrency as a trader and instructor. She has served as a KOL and brand ambassador   <span onClick={(() => { readMore(5) })} className='cursor-pointer text-brand font-medium'> read more ...</span>
                                        </div>

                                        {/* <!-- Social Icons --> */}
                                        <div class="flex items-center justify-center opacity-50 hover:opacity-100
                                transition-opacity duration-300">
                                            {/* <!-- Linkedin --> */}
                                            <a href="#" class="flex rounded-full hover:bg-indigo-50 h-10 w-10">
                                                <i class="mdi mdi-linkedin text-indigo-700 mx-auto mt-2"></i>
                                            </a>

                                            {/* <!-- Twitter --> */}
                                            <a href="#" class="flex rounded-full hover:bg-blue-50 h-10 w-10">
                                                <i class="mdi mdi-twitter text-blue-400 mx-auto mt-2"></i>
                                            </a>

                                            {/* <!-- Instagram --> */}
                                            <a href="#" class="flex rounded-full hover:bg-orange-50 h-10 w-10">
                                                <i class="mdi mdi-instagram text-orange-400 mx-auto mt-2"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </motion.div>
                            {/* <!-- Member #6 --> */}
                            <motion.div
                                variants={FADE_DOWN_ANIMATION_VARIANTS}
                                class="w-full md:w-6/12 lg:w-3/12 mb-6 px-6 sm:px-6 lg:px-4">
                                <div class="flex flex-col">
                                    {/* <!-- Avatar --> */}
                                    <a href="#" class="mx-auto">
                                        <img class="rounded-2xl w-60 h-72 drop-shadow-md hover:drop-shadow-xl transition-all duration-200 delay-100"
                                            src={member6} />
                                    </a>

                                    {/* <!-- Details --> */}
                                    <div class="text-center mt-6">
                                        {/* <!-- Name --> */}
                                        <h1 class=" text-xl font-bold mb-1">
                                            Muhammad Taufique
                                        </h1>

                                        {/* <!-- Title --> */}
                                        <div class=" font-light mb-2 justify-center flex  gap-2 w-full">
                                        Development Advisor <a href='https://www.linkedin.com/in/m-taufique/' target='_blank' >
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 21a9.004 9.004 0 0 0 8.716-6.747M12 21a9.004 9.004 0 0 1-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 0 1 7.843 4.582M12 3a8.997 8.997 0 0 0-7.843 4.582m15.686 0A11.953 11.953 0 0 1 12 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0 1 21 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0 1 12 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 0 1 3 12c0-1.605.42-3.113 1.157-4.418" />
                                            </svg>
                                            </a>
                                        </div>
                                        <div class=" font-light mb-2 text-start">
                                            Taufique is a Co-Founder @ Blockliz, a cutting-edge technology company that is revolutionizing the way we access the internet. With his   <span onClick={(() => { readMore(4) })} className='cursor-pointer text-brand font-medium'> read more ...</span>
                                        </div>

                                        {/* <!-- Social Icons --> */}
                                        <div class="flex items-center justify-center opacity-50 hover:opacity-100
                                transition-opacity duration-300">
                                            {/* <!-- Linkedin --> */}
                                            <a href="#" class="flex rounded-full hover:bg-indigo-50 h-10 w-10">
                                                <i class="mdi mdi-linkedin text-indigo-700 mx-auto mt-2"></i>
                                            </a>

                                            {/* <!-- Twitter --> */}
                                            <a href="#" class="flex rounded-full hover:bg-blue-50 h-10 w-10">
                                                <i class="mdi mdi-twitter text-blue-400 mx-auto mt-2"></i>
                                            </a>

                                            {/* <!-- Instagram --> */}
                                            <a href="#" class="flex rounded-full hover:bg-orange-50 h-10 w-10">
                                                <i class="mdi mdi-instagram text-orange-400 mx-auto mt-2"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </motion.div>
                        </div>
                    </div>
                </div>
            </motion.div>
            <TeamInfo
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                info={info}
                index={index}
            />
        </div>
    )
}

export default Teams
