import React from "react";
import Highcharts from "highcharts";
import Highcharts3D from "highcharts/highcharts-3d"; // Import the 3D module
import HighchartsReact from "highcharts-react-official";
import { useTheme } from "next-themes"; 
import whitepaperPdf from './../../assets/whitepaper.pdf'
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { FADE_DOWN_ANIMATION_VARIANTS } from "../../lib/framer";


Highcharts3D(Highcharts); // Initialize the 3D module

export default function Tokenomics() {

  const { theme } = useTheme(); // Access the current theme using useTheme hook


 

  
  function generateShades(startHex, endHex, grade) {
    var start = [
      parseInt(startHex.substring(1, 3), 16),
      parseInt(startHex.substring(3, 5), 16),
      parseInt(startHex.substring(5, 7), 16),
    ];
    var end = [
      parseInt(endHex.substring(1, 3), 16),
      parseInt(endHex.substring(3, 5), 16),
      parseInt(endHex.substring(5, 7), 16),
    ];
    var shades = [];
    for (var i = 0; i < grade; i++) {
      var r = start[0] * (1 - i / (grade - 1)) + end[0] * (i / (grade - 1));
      var g = start[1] * (1 - i / (grade - 1)) + end[1] * (i / (grade - 1));
      var b = start[2] * (1 - i / (grade - 1)) + end[2] * (i / (grade - 1));
      shades.push(`rgb(${parseInt(r)},${parseInt(g)},${parseInt(b)})`);
    }
    return shades;
  }


  const labelColor = theme === "dark" ? 'rgb(209 213 219)' : 'rgb(79 79 79)';


  
  const options = {
    chart: {
      type: 'pie',
      backgroundColor: 'transparent',
    
    },
    title: {
      text: ''
    },
    plotOptions: {
      pie: {
        innerSize: '50%',
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '{point.name}: {point.y}%',
          color: labelColor,
          textOutline: false,
          
        },
        showInLegend: true,
      }
      
    },
    legend: {
      itemStyle: {
          color: labelColor // Change the color of the legend items to blue
      },
    },
    colors: generateShades('#80DEB9', '#307358', 7),
    series: [{
      name: 'Token Distribution',
      data: [
        { name: 'Presale', y: 50 },
        { name: 'Team', y: 20 },
        { name: 'DEX Listing', y: 10 },
        { name: 'Ecosystem', y: 10 },
        { name: 'Marketing', y: 5 },
        { name: 'Free Airdrop', y: 2 },
        { name: 'Legal & Compliance', y: 3 }
      ]
    }],
    tooltip: {
      formatter: function() {
        return  this.point.name + ': <b>' + (this.y * 5) + 'M</b>';
      }
  },
    credits: {
      enabled: false
    },
   
  };

  const { ref, inView } = useInView({
    triggerOnce: false,
  });

  return (
    <>
      <motion.div ref={ref}
          initial="hidden"
          animate={inView ? "show" : "hidden"}
          viewport={{ once: true }}
          variants={{
            hidden: {},
            show: {
              transition: {
                staggerChildren: 0.2,
              },
            },
          }} id="Tokenomics" className="flex flex-col justify-center items-center overflow-hidden pt-[3rem]">
        <div className="space-y-5 text-center ">
          <motion.h2
            variants={FADE_DOWN_ANIMATION_VARIANTS}
            className="text2 text-[2.2rem] text-heading dark:text-gray-300 md:text-[2.5rem] font-bold text-center"
          >
            Tokenomics: Understanding Our <br /> Token Distribution
          </motion.h2>
          <motion.p
            variants={FADE_DOWN_ANIMATION_VARIANTS}
            className="mx-auto px-2 max-w-[1103px] text-para dark:text-gray-300 text-[1rem] md:text-[1.125rem] "
          >
            Our token distribution is carefully structured to ensure fairness,
            stability, and long-term growth. Through strategic allocation, we
            aim to incentivize participation, reward early supporters, and
            sustain the ecosystem's health.
          </motion.p>
          <motion.p
            variants={FADE_DOWN_ANIMATION_VARIANTS}
            className="mx-auto px-2 max-w-[1103px] text-para dark:text-gray-300 text-[1rem] md:text-[1.125rem] "
          >
            For a detailed explanation of our tokenomics and distribution
            strategy, explore our whitepaper or contact our team for <br />{" "}
            further information.
          </motion.p>
        </div>

        <div className="flex flex-col md:flex-row items-center justify-between gap-[5rem]  pt-[4rem] p-4 ">
          <motion.div variants={FADE_DOWN_ANIMATION_VARIANTS} className="border-2 dark:border-gray-700  rounded-[2rem]   p-6 text-gray-200  bg-gradient-to-tr from-slate-50 via-gray-300 to-stone-50  dark:bg-gray-800  dark:from-gray-800 dark:to-gray-800 boxShadow">
            
            <h4 className="text-heading dark:text-gray-200 font-bold text-[1.5rem] md:text-[1.8rem] text2">DGFL Token Metrics</h4>

            <div className="flex flex-col gap-[1rem]  pt-5">
            <div className="flex justify-between ">
              <p className="text-para  dark:text-gray-200 text-[0.8rem] md:text-[1rem]">Presale: </p>
              <p className="text-heading dark:text-gray-200 text-[0.8rem] md:text-[1rem] font-bold text1">50% 250m (each stage 50m)
</p>
            </div>
            <div className="flex justify-between ">
              <p className="text-para  dark:text-gray-200 text-[0.8rem] md:text-[1rem]">Team: </p>
              <p className="text-heading dark:text-gray-200 text-[0.8rem] md:text-[1rem] font-bold text1">20% 100m (Locked)
</p>
            </div>
            <div className="flex justify-between ">
              <p className="text-para  dark:text-gray-200 text-[0.8rem] md:text-[1rem]">DEX listing: </p>
              <p className="text-heading dark:text-gray-200 text-[0.8rem] md:text-[1rem] font-bold text1 text-start">10% 50m</p>
            </div>
            <div className="flex justify-between ">
              <p className="text-para  dark:text-gray-200 text-[0.8rem] md:text-[1rem]">Ecosystem: </p>
              <p className="text-heading dark:text-gray-200 text-[0.8rem] md:text-[1rem] font-bold text1 text-start">10% 50m</p>
            </div>
            <div className="flex justify-between ">
              <p className="text-para  dark:text-gray-200 text-[0.8rem] md:text-[1rem]">Marketing: </p>
              <p className="text-heading dark:text-gray-200 text-[0.8rem] md:text-[1rem] font-bold text1 text-start">5% 25m
</p>
            </div>
            <div className="flex justify-between ">
              <p className="text-para  dark:text-gray-200 text-[0.8rem] md:text-[1rem]">Free airdrop: </p>
              <p className="text-heading dark:text-gray-200 text-[0.8rem] md:text-[1rem] font-bold text1 text-start">2% 10m</p>
            </div>
            <div className="flex justify-between ">
              <p className="text-para  dark:text-gray-200 text-[0.8rem] md:text-[1rem]">legal & compliance: </p>
              <p className="text-heading dark:text-gray-200 text-[0.8rem] md:text-[1rem] font-bold text1 text-start">3% 15m</p>
            </div>
            
            </div>
          </motion.div>

          <motion.div variants={FADE_DOWN_ANIMATION_VARIANTS} className="pt-4 w-[25rem] md:w-[50rem] ">
            <HighchartsReact
              highcharts={Highcharts}
              options={options}
              containerProps={{ style: { height: "100%", width: "100%" } }}
            />
          </motion.div>
          
        </div>

        <motion.div
          variants={FADE_DOWN_ANIMATION_VARIANTS}
          className="pt-[1rem] md:pt-[2rem]"
        >
          <a
             type="button"
             href={"https://digifolios.com/whitepaper.pdf"} target='_blank'
            className="text-brand text2 mt-10 md:mt-0 hover:bg-brand  font-medium rounded-full text-[1.1rem] px-5 py-2.5 text-center inline-flex items-center border-1 border-brand hover:text-white transition duration-150 ease-in-out light:bg-gradient-to-tl from-gray-300/80 via-gray-200/80 to-gray-300/80 dark:bg-transparent dark:text-brand dark:border-brand dark:hover:text-white dark:hover:border-brand dark:hover:bg-brand  "
          >
            View Whitpaper
            <svg
              className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 10"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M1 5h12m0 0L9 1m4 4L9 9"
              />
            </svg>
          </a>
        </motion.div>
      </motion.div>
    </>
  );
}
